import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import cn from "classnames"
import InfoModule from "../components/InfoModule/index"
import LOYALTY_IMAGE from "../images/loyalty-image.svg"
import FIXTURE_IMAGE from "../images/fixture-image.svg"
import STARTED_IMAGE from "../images/started-image.svg"
import CARGO_IMAGE from "../images/auto-cargo-image.svg"
import VOYAGE_IMAGE from "../images/voyage-image.svg"
import SHORTLISTING_IMAGE from "../images/shortlisting-image.svg"
import PARSER_IMAGE from "../images/parser-image.svg"
import EXPORT_IMAGE from "../images/export-image.svg"
import MAINTENANCE_LOGO from "../images/maintenance-logo.svg"
import SYNC_LOGO from "../images/sync-logo.svg"
import MILITARY_LOGO from "../images/military-logo.svg"

import Data from "../data/data"

import style from "./ship-brokers.module.css"

const ShipBrokers = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{Data.shipBrokers.title}</title>
          <meta
            name="description"
            content={Data.metaData.description.shipBrokers}
          />
          <link
            href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        {/* Banner */}
        <div className={style.root}>
          <div className={style.brokers__banner}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.brokers__bannerContent}>
                    <h2 className={style.brokers__title}>
                      {Data.shipBrokers.pageTitle}
                    </h2>
                    <h1 className={style.brokers__heading}>
                      {Data.shipBrokers.heading}
                    </h1>
                    <div className="row">
                      <div className="col s12">
                        <p className={style.brokers__subHeading}>
                          {Data.shipBrokers.subHeading}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Loyalty Section */}
          <div className={style.loyalty}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.loyalty__module}>
                    <InfoModule
                      image={LOYALTY_IMAGE}
                      imageAlt={"Loyalty image"}
                      heading={Data.shipBrokers.loyaltyHeading}
                      headingLarge={true}
                      rowOneText={Data.shipBrokers.loyaltyParagraphOne}
                      rowTwoText={Data.shipBrokers.loyaltyParagraphTwo}
                      rowThreeText={Data.shipBrokers.loyaltyParagraphThree}
                      reverseContentPosition={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fixture Section */}
          <div className={style.fixture}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.fixture__module}>
                    <InfoModule
                      image={FIXTURE_IMAGE}
                      imageAlt={"Fixture image"}
                      heading={"Win more fixtures with faster response times"}
                      headingLarge={true}
                      rowOneText={Data.shipBrokers.fixtureParagraphOne}
                      rowTwoText={Data.shipBrokers.fixtureParagraphTwo}
                      rowThreeText={Data.shipBrokers.fixtureParagraphThree}
                      removeTextMargin={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Started Section */}
          <div className={style.started}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.started__module}>
                    <InfoModule
                      image={STARTED_IMAGE}
                      imageAlt={"Started image"}
                      heading={Data.shipBrokers.startedHeading}
                      headingLarge={true}
                      rowOneText={Data.shipBrokers.startedParagraphOne}
                      rowTwoText={Data.shipBrokers.startedParagraphTwo}
                      rowThreeText={Data.shipBrokers.startedParagraphThree}
                      reverseContentPosition={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Features Section */}
          <div className={style.features}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.features__content}>
                    <div className={style.features__heading}>
                      {Data.shipBrokers.coreFeaturesSectionHeading}
                    </div>

                    {/* Automatic Cargo */}
                    <div className={style.features__group}>
                      <img
                        className={style.features__groupImage}
                        src={CARGO_IMAGE}
                        alt="cargo logo"
                      />
                      <div className={style.features__textGroup}>
                        <h4 className={style.features__groupHeading}>
                          {Data.shipBrokers.cargoHeading}
                        </h4>
                        <p className={style.features__groupText}>
                          {Data.shipBrokers.cargoParagraphOne}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.cargoParagraphTwo}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.cargoParagraphThree}
                        </p>
                      </div>
                    </div>

                    {/* Voyage Estimator */}
                    <div className={cn(style.features__group, style.voyage)}>
                      <img
                        className={cn(
                          style.features__groupImage,
                          style.voyageImage
                        )}
                        src={VOYAGE_IMAGE}
                        alt="voyage"
                      />
                      <div
                        className={cn(
                          style.features__textGroup,
                          style.voyageText
                        )}
                      >
                        <h4 className={style.features__groupHeading}>
                          {Data.shipBrokers.voyageHeading}
                        </h4>
                        <p className={style.features__groupText}>
                          {Data.shipBrokers.voyageParagraphOne}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.voyageParagraphTwo}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.voyageParagraphThree}
                        </p>
                      </div>
                    </div>

                    {/* Shortlisting */}
                    <div
                      className={cn(style.features__group, style.shortlisting)}
                    >
                      <img
                        className={cn(
                          style.features__groupImage,
                          style.shortlistingImage
                        )}
                        src={SHORTLISTING_IMAGE}
                        alt="Shortlisting"
                      />
                      <div
                        className={cn(
                          style.features__textGroup,
                          style.shortlistingText
                        )}
                      >
                        <h4 className={style.features__groupHeading}>
                          {Data.shipBrokers.shortlistingHeading}
                        </h4>
                        <p className={style.features__groupText}>
                          {Data.shipBrokers.shortlistingParagraphOne}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.shortlistingParagraphTwo}
                        </p>
                      </div>
                    </div>

                    {/* Chat Parser */}
                    <div className={cn(style.features__group, style.parser)}>
                      <img
                        className={cn(
                          style.features__groupImage,
                          style.parserImage
                        )}
                        src={PARSER_IMAGE}
                        alt="Parser"
                      />
                      <div
                        className={cn(
                          style.features__textGroup,
                          style.parserText
                        )}
                      >
                        <h4 className={style.features__groupHeading}>
                          {Data.shipBrokers.parserHeading}
                        </h4>
                        <p className={style.features__groupText}>
                          {Data.shipBrokers.parserParagraphOne}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.parserParagraphTwo}
                        </p>
                      </div>
                    </div>

                    {/* Export List */}
                    <div className={cn(style.features__group, style.export)}>
                      <img
                        className={cn(
                          style.features__groupImage,
                          style.exportImage
                        )}
                        src={EXPORT_IMAGE}
                        alt="Export"
                      />
                      <div
                        className={cn(
                          style.features__textGroup,
                          style.exportText
                        )}
                      >
                        <h4 className={style.features__groupHeading}>
                          {Data.shipBrokers.exportHeading}
                        </h4>
                        <p className={style.features__groupText}>
                          {Data.shipBrokers.exportParagraphOne}
                        </p>

                        <p className={style.features__groupText}>
                          {Data.shipBrokers.exportParagraphTwo}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Features Section */}
          <div className={style.peaceOfMind}>
            <div className="container">
              <div className="row">
                <div className="col s12">
                  <h4 className={style.peaceOfMind__heading}>
                    {Data.shipBrokers.peaceOfMindHeading}
                  </h4>
                  <div className={style.peaceOfMind__group}>
                    <div className={style.peaceOfMind__module}>
                      <img
                        className={cn(style.peaceOfMind__img, style.impact)}
                        src={MAINTENANCE_LOGO}
                        alt="Maintenance Logo"
                      />
                      <div className={style.peaceOfMind__textWrap}>
                        <p className={style.peaceOfMind__text}>
                          {Data.shipBrokers.peaceOfMindModuleOneText}
                        </p>
                      </div>
                    </div>

                    <div className={style.peaceOfMind__module}>
                      <img
                        className={cn(style.peaceOfMind__img, style.impact)}
                        src={SYNC_LOGO}
                        alt="Sync Logo"
                      />
                      <div className={style.peaceOfMind__textWrap}>
                        <p className={style.peaceOfMind__text}>
                          {Data.shipBrokers.peaceOfMindModuleTwoText}
                        </p>
                      </div>
                    </div>

                    <div className={style.peaceOfMind__module}>
                      <img
                        className={cn(style.peaceOfMind__img, style.impact)}
                        src={MILITARY_LOGO}
                        alt="Military Logo"
                      />
                      <div
                        className={cn(
                          style.peaceOfMind__textWrap,
                          style.militaryGrade
                        )}
                      >
                        <p className={style.peaceOfMind__text}>
                          {Data.shipBrokers.peaceOfMindModuleThreeText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

ShipBrokers.propTypes = {}

export default ShipBrokers
